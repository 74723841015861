<template>
  <div class="container">


    <div class="graduate-work-calendar mt-4 mb-4">
      <h4 class="text-center mb-4">Календарный план-график выполения дипломных работ</h4>


      <div class="row mt-4">
        <div class="col-md-6">
          <label>Фильтрация по уровню образования</label>
          <select class="form-control form-select" id="study_level"
                  @input="changeStudyLevel($event)">
            <option v-for="(item, index) in [{id: 0, name: 'Выберите уровень обучения'}, ...graduateWorkCalendar_form.studyLevels]"
                    :value="item.id"
                    :key="index">{{ item.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="mt-4" v-if="graduateWorkCalendar_form.graduateWorkCalendarInfo.length != 0">
        <DataTable :value="graduateWorkCalendar_form.graduateWorkCalendarInfo" responsiveLayout="scroll">
          <Column field="step_number" header="№"></Column>
          <Column header="Этапы работы">
            <template #body="{data}">
              <input type="text" v-model="data.steps" class="form-control">
            </template>
          </Column>

          <Column header="Сроки выполнения">
            <template #body="{data}">


              <div v-if="data.from_date == null && data?.to_date">
                до {{ convertGraduateWorkCalendarTimestampToDate(data?.to_date) }}
              </div>
              <div v-if="data.to_date == null && data?.from_date">
                {{ convertGraduateWorkCalendarTimestampToDate(data?.from_date) }} - {{ convertGraduateWorkCalendarTimestampToDate(data?.to_date) }}
              </div>
            </template>
          </Column>

        </DataTable>


        <div class="mt-4 text-center">
          <Button label="Сохранить" @click="save"/>
        </div>
      </div>


      <!--      Форма для бакалавриата-->
      <!--      <div v-if="selectedStudyLevelId == 1" class="mt-4">-->
      <!--        <p>Бакалавр</p>-->
      <!--        <BachelorForm />-->
      <!--      </div>-->

      <!--      Форма для магистратуры (1 год)-->
      <!--      <div v-if="selectedStudyLevelId == 2">-->
      <!--        <p>Магистратура (1 годичное,профильное)</p>-->
      <!--        <OneYearMagistracyForm />-->
      <!--      </div>-->

      <!--      Форма для магистратуры (1.5 год)-->
      <!--      <div v-if="selectedStudyLevelId == 3">-->
      <!--        <p>Магистратура (1,5 годичное, профильное)</p>-->
      <!--      </div>-->

      <!--      Форма для магистратуры (2 года)-->
      <!--      <div v-if="selectedStudyLevelId == 4">-->
      <!--        <p>Магистратура (2 годичное, научно-педагогическое)</p>-->
      <!--        <TwoYearMagistracyForm />-->
      <!--      </div>-->




    </div>

  </div>



</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import {convertGraduateWorkCalendarTimestampToDate} from "@/utils/helpers/convertGraduateWorkCalendarTimestampToDate"
//import BachelorForm from '@/components/graduate-work/dean/BachelorForm.vue'
//import OneYearMagistracyForm from '@/components/graduate-work/dean/OneYearMagistracyForm.vue'
//import TwoYearMagistracyForm from '@/components/graduate-work/dean/TwoYearMagistracyForm.vue'
export default {
  name: "GraduateWorkCalendar",
  components: {
    //BachelorForm,
    //OneYearMagistracyForm,
    //TwoYearMagistracyForm
  },
  data() {
    return {
      selectedStudyLevelId: null,
      //filteredGraduateWorkCalendarInfos: null
    }
  },
  computed: {
    ...mapState('graduateWorkCalendar', ['graduateWorkCalendar_form']),
  },

  methods: {
    ...mapActions('graduateWorkCalendar', ['GET_STUDY_LEVELS', 'GET_GRADUATE_WORK_CALENDAR', 'PUT_GRADUATE_WORK_CALENDAR']),
    ...mapMutations('graduateWorkCalendar', ['']),
    convertGraduateWorkCalendarTimestampToDate,
    async changeStudyLevel(e) {
      const value = e.target.value
      this.selectedStudyLevelId = value
      console.log(this.selectedStudyLevelId, "selectedStudyLevelId")

      await this.GET_GRADUATE_WORK_CALENDAR(this.selectedStudyLevelId)
      //this.filteredGraduateWorkCalendarInfos = this.graduateWorkCalendar_form.graduateWorkCalendarInfos.filter(i => i.study_level_id == this.selectedStudyLevelId)


    },
    async save(){
      const res = await this.PUT_GRADUATE_WORK_CALENDAR(this.selectedStudyLevelId)
      if(res){
       this.$message({title: 'Сохранено'})
      }
    }
  },

  async mounted() {
    await this.GET_STUDY_LEVELS()
    //await this.GET_GRADUATE_WORK_CALENDAR(this.selectedStudyLevelId)
  }
}
</script>

<style scoped>

</style>
